import styles from "./HomeLandingPage.module.css"

export default function LandingHeroAndLogos({category}) {
  if(category) return;

  return(
    <>
      <div className={styles["hero-video-container"]}>
        <div className="container">
        </div>
      </div>
    </>
  )
}